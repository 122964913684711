<template>
  <div class="indexSty">
    <el-carousel>
      <el-carousel-item v-for="item in images" :key="item">
        <img :src="item" style="width:100%" alt="" />
        <!-- <el-image fit="contain" lazy-load :src="item" style="width:100%" /> -->
      </el-carousel-item>
    </el-carousel>

    <div style="margin-top:2rem">
      <el-image fit="contain" :src="jjfa" style="width:6rem; height:6rem" />
    </div>
    <div>
      <el-row :gutter="20">
        <router-link :to="{ path: '/components/mobile/chanpin' }">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-image
                fit="contain"
                :src="icon1"
                style="width:6rem; height:6rem"
              />
              <div style="color:#4a58ff; font-weight:bold;">
                {{ $t('Nav.qiyezonghehuaguanli') }}
              </div>
              <div style="font-size:14px">{{ $t('Nav.indexText01') }}</div>
            </div>
          </el-col>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin' }">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-image
                fit="contain"
                :src="icon2"
                style="width:6rem; height:6rem"
              />
              <div style="color:#4a58ff; font-weight:bold;">
                {{ $t('Nav.nengyuanshihuaguanli') }}
              </div>
              <div style="font-size:14px">
                {{ $t('Nav.indexText02') }}
              </div>
            </div>
          </el-col>
        </router-link>
      </el-row>
      <el-row :gutter="20" style="margin-top:2rem;">
        <router-link :to="{ path: '/components/mobile/chanpin' }">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-image
                fit="contain"
                :src="icon3"
                style="width:6rem; height:6rem"
              />
              <div style="color:#4a58ff; font-weight:bold;">
                {{ $t('Nav.guojiyunying') }}
              </div>
              <div style="font-size:14px">{{ $t('Nav.indexText03') }}</div>
            </div>
          </el-col>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin' }">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <el-image
                fit="contain"
                :src="icon4"
                style="width:6rem; height:6rem"
              />
              <div style="color:#4a58ff; font-weight:bold;">
                {{ $t('Nav.dashuju') }}
              </div>
              <div style="font-size:14px">
                {{ $t('Nav.indexText04') }}
              </div>
            </div>
          </el-col>
        </router-link>
      </el-row>
    </div>
    <div style="position: relative;clear: both;height:62rem">
      <el-image fit="contain" :src="itc" style="width:9rem; height:6rem" />
      <div style="position:absolute;top:9rem; ">
        <router-link :to="{ path: '/components/mobile/itc' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc1"
              style="width:100%;margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.quanfangweiitc') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/itc' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc2"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px;height:30px; line-height:30px; background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.quanqiuopo') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/ict-8' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc3"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.anlifenxiang') }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div style="height:30px"></div>
    <img :src="zizhiUrl" alt="" style="width:100%;" />

    <div style="height:30px"></div>
    <div class="hzgs">{{ $t('Nav.hezuogongsi') }}</div>
    <el-divider></el-divider>

    <el-row :gutter="24">
      <el-col :span="4">
        <el-image
          class="cake"
          :src="hezuo1"
          fit="contain"
          style="width:100%; height:50px"
        ></el-image
      ></el-col>
      <el-col :span="4">
        <el-image
          :src="hezuo2"
          class="cake"
          fit="contain"
          style="width:100%; height:50px"
        ></el-image>
      </el-col>
      <el-col :span="4">
        <el-image
          :src="hezuo3"
          class="cake"
          fit="contain"
          style="width:100%; height:50px"
        ></el-image>
      </el-col>
      <el-col :span="4">
        <el-image
          :src="hezuo4"
          class="cake"
          fit="contain"
          style="width:100%; height:50px"
        ></el-image>
      </el-col>
      <!-- <el-col :span="2">
          <el-image
            :src="hezuo5"
            class="cake"
            fit="contain"
            style="width:100%; height:50px"
          ></el-image>
        </el-col> -->
      <el-col :span="4">
        <el-image
          :src="hezuo6"
          class="cake"
          fit="contain"
          style="width:100%; height:50px"
        ></el-image>
      </el-col>
      <el-col :span="4">
        <el-image
          :src="hezuo7"
          fit="contain"
          class="cake"
          style="width:100%; height:50px"
        ></el-image>
      </el-col>
    </el-row>

    <div style="clear: both; overflow: hidden;margin-bottom:20px;"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Lazyload } from 'vant'
Vue.use(Lazyload)
export default {
  name: 'index',
  components: {
    // HelloWorld
  },
  data() {
    return {
      images: [
        require('../../assets/mobile/01.png'),
        require('../../assets/mobile/02.png'),
        require('../../assets/index/03.jpg'),
        require('../../assets/index/04.jpg'),
        require('../../assets/index/05.jpg'),
      ],
      jjfa: require('../../assets/index/jjfatitle.png'),
      itc: require('../../assets/index/ICT.jpg'),
      icon1: require('../../assets/mobile/01-icon1@2x.png'),
      icon2: require('../../assets/mobile/01-icon2@2x.png'),
      icon3: require('../../assets/mobile/01-icon3@2x.png'),
      icon4: require('../../assets/mobile/01-icon4@2x.png'),
      itc1: require('../../assets/itc/1.jpg'),
      itc2: require('../../assets/itc/5.jpg'),
      itc3: require('../../assets/itc/anli.png'),
      hezuo1: require('../../assets/index/hezuo1.png'),
      hezuo2: require('../../assets/index/hezuo2.png'),
      hezuo3: require('../../assets/index/hezuo3.png'),
      hezuo4: require('../../assets/index/hezuo4.png'),
      hezuo5: require('../../assets/index/hezuo5.png'),
      hezuo6: require('../../assets/index/hezuo6.png'),
      hezuo7: require('../../assets/index/bp.jpg'),
      zizhiUrl: require('../../assets/index/banner02.jpg'),
    }
  },
  methods: {},
}
</script>

<style>
.indexSty .el-carousel__container {
  height: 458px !important;
}
.indexSty .el-carousel__indicators--horizontal {
  left: 49% !important;
}
</style>
